<template>
  <div class="text-yellow-400">
    <i class="fas fa-star" title="Favorite" v-if="checked"></i>
    <i class="far fa-star" title="Not favorite" v-else></i>
  </div>
</template>

<script>
export default {
  name: 'IconStar',
  components: {},
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
</style>
